import Actions from '@/components/actions';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import useGetDeviceInfo from '@/hooks/useGetDeviceInfo';
import { Order } from '@/types/schema';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { type Theme, useMediaQuery } from '@mui/material';
import axios from 'axios';
import { isEmpty } from 'lodash-es';
import { useSnackbar } from 'notistack';

export default function DeletedOrdersMultiActions( {
	orders,
	clearRows,
	selectedOrders,
}: {
	orders: string[],
	clearRows: () => void,
	selectedOrders: Order[]
} ) {
	const { isCloverDevice } = useGetDeviceInfo();
	
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	const isIpad = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'lg' ) );
	const confirmDialog = useConfirmDialog();
	const { enqueueSnackbar } = useSnackbar();
	
	const invoiceWithCloverGateway = selectedOrders[ 0 ]?.gateway?.external === 'CLOVER' && selectedOrders[ 0 ];
	const sameGateway = selectedOrders.every( ( invoice ) => invoice.gateway?.id === invoiceWithCloverGateway.gateway?.id && invoice.externalId );
	const externalIds = selectedOrders.map( ( invoice ) => invoice.externalId );
	const noExternalIds = isEmpty( externalIds );
	
	return (
		<Actions
			separated
			max={isMobile ? 1 : isCloverDevice || isIpad ? 4 : 0}
			items={[ {
				name    : `${'Delete'} from Clover`,
				icon    : <DeleteIcon/>,
				details : !sameGateway || noExternalIds ? 'Please select orders for the same Clover location.' : '',
				disabled: !sameGateway || noExternalIds,
				onClick : async () => {
					const value = await confirmDialog( {
						title  : `${'Delete'} from Clover`,
						message: `Are you sure you want to delete ${selectedOrders.length > 0
							? 'these orders'
							: 'this order'} from Clover as well?`,
					} );
					if ( !value ) return;
					await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/deleteOrders`, {
						externalIds,
						gatewayId: invoiceWithCloverGateway?.gateway?.id,
					} );
					enqueueSnackbar( 'Orders deleted successfully', { variant: 'success' } );
					clearRows();
				},
				
			} ]}
		/>
	);
	
}
